<template>
  <div class="wrapper">
    <div class="page">
      <div id="loader"></div>
      <div class="page-inner" id="main_page">

        <div class="va-card">
          <div class="row">
            
            <div class="col-lg-12">
              <div class="dash_card_body">

                <div class="header_section">
                  <h4 class="menu_header">Menus</h4>
                  <div class="">
                    <router-link :to="{ name: 'createmenu' }">
                      <button class="btn btn-default custom_btn custon_orange">
                        <i class="fa fa-plus"></i> New menu
                      </button>
                    </router-link>
                  </div>
                </div>

                <div class="search_section">
                  <div class="row">
                    <div class="col-md-5">
                      <div class="form-group has-search mt-1">
                        <span class="fa fa-search form-control-feedback"></span>
                        <input type="text" v-model="search" @keyup="searchMenus()" aria-required="false"
                          autocomplete="on" inputmode="text" name="search_input" placeholder="Search..."
                          class="form-control">
                      </div>
                    </div>
                    <div class="col-md-5">
                      <select class="form-control mt-1" @change="searchMenus()" v-model="menuStatus">
                        <option value="">--Select status --</option>
                        <option value="1">Published</option>
                        <option value="2">Unpublished</option>
                      </select>
                    </div>


                  </div>
                </div>
              </div>
              <hr>
            </div>



            <div class="col-lg-12">
              <!--Filters-->
              <div class="filters" id="filter_bar" style="display:none">
                <div class="" v-if="adminRoles.includes(user.role.id)">
                  <span class="btn-filter mt-1">
                    <input type="checkbox" id="uncheck" @click="checkItems('uncheck')" indeterminate=true>
                  </span>
                  <button class="btn-filter mr-1 mt-1">
                    {{menuSelected >1 ? menuSelected + ' menus': menuSelected + ' menu'}} selected
                  </button>
                  <button class="btn-filter mr-2 mt-1" @click="bulkStatusUpdate('publish', 1)">
                    <i class='fa fa-check' style="color:green;"></i>
                    <span v-if="methodSpinner == 'publish'"><span v-html="spinner"></span> </span> <span v-else>
                      Publish</span> 
                  </button>
                  <button class="btn-filter mr-2 mt-1" @click="bulkStatusUpdate('unpublish', 2)">
                    <i class="fa fa-close" style="color:red;" aria-hidden="true"></i>
                    <span v-if="methodSpinner == 'unpublish'"><span v-html="spinner"></span></span> <span v-else>
                      Unpublish</span> </button>
                </div>
              </div>

              <div class="dash_card_body pt-0">
                <div class="table_section">
                  <table class="table  table-hover">
                    <thead>
                      <tr>
                        <th width="5px">
                          <input type="checkbox" id="check_all" @click="checkItems('check')">
                        </th>
                        <th scope="col">Name</th>
                        <th scope="col">Categories</th>
                        <th scope="col">Status</th>
                        <th scope="col">Last Updated</th>
                      </tr>
                    </thead>
                    <tbody v-if="!loading">
                      <tr v-for="menu in menus" :key="menu.id" draggable="true"  @drop="onDrop($event,menu)" @dragenter.prevent @dragover.prevent @dragstart="startDrag($event,menu)">
                        <td>
                          <input type="checkbox" :id="'menu_' + menu.id" @click="selectMenus(menu.id)">
                        </td>
                        <td>
                          <router-link :to="{ name: 'updatemenu', params: { menuid: menu.id } }">
                            <span class="title_text">{{ menu.title }}</span>
                          </router-link>
                        </td>
                        <td>
                          <span v-if="menu.categories !== null">
                            <div @mouseenter="catTooltip = menu.id" @mouseleave="catTooltip = 0">
                              <span v-for="(category, catindex) in menu.categories.slice(0, 4)" :key="catindex">
                                {{ category.title }}
                                <span v-if="catindex + 1 < menu.categories.length">, </span>
                              </span>
                              <span v-if="menu.categories.length > 3">..+ {{ parseInt(menu.categories.length) -
                                3
                              }}</span>
                            </div>

                            <!-- tool tip -->
                            <div class="vtooltiptext" v-show="catTooltip == menu.id && menu.categories.length > 3">
                              <div class="row">
                                <span class="col-md-3" v-for="(i_category, icatindex) in menu.categories"
                                  :key="icatindex">
                                  . {{ i_category.title }}
                                  <!-- <span v-if="icatindex + 1 < menu.categories.length "> , </span> -->
                                </span>
                              </div>

                            </div>
                            <!-- end -->
                          </span>
                          <span v-else>-</span>
                        </td>
                        <!-- status -->
                        <td>
                          <div class="btn-group">
                            <button class="btn custom_btn btn-sm dropdown-toggle'" type="button">
                              <span v-if="menu.status == 1">
                                <i class='fa fa-check' style="color:green;"></i>
                              </span>
                              <span v-else-if="menu.status == 2">
                                <i class="fa fa-close" style="color:red;" aria-hidden="true"></i>
                              </span>
                              <span v-else-if="menu.status == 3">
                                <i class="fa fa-archive" aria-hidden="true"></i>
                              </span>
                              <span v-else>
                                <i class="fa fa-trash-o" aria-hidden="true"></i>
                              </span>
                            </button>
                            <button v-if="adminRoles.includes(user.role.id)" type="button"
                              class="btn btn-sm custom_btn btn-default dropdown-toggle" data-toggle="dropdown"
                              :id="'dropdownMenuButton' + menu.id">
                              <span class="caret"></span>
                              <span class="sr-only">Toggle Dropdown</span>
                            </button>
                            <div class="dropdown-menu" :aria-labelledby="'dropdownMenuButton' + menu.id">
                              <a class="dropdown-item" @click="updateStatsPerItem(menu.id, 1)" href="#">Publish</a>
                              <a class="dropdown-item" @click="updateStatsPerItem(menu.id, 2)"
                                href="#">Unpublish</a>
                            </div>
                          </div>
                        </td>
                        <td>
                          <span v-if="menu.updated_at !== null">{{ formatDate(menu.updated_at) }}</span>
                          <span v-else>{{ formatDate(menu.created_at) }}</span>
                        </td>
                      </tr>
                    </tbody>
                    <tfoot v-if="!loading">
                      <tr>
                        <td colspan="8">
                          <div id="pagination">
                            <ul class="showItems">
                              <li>Show Items:
                                <select @change="onChangeRecordsPerPage" v-model="recordsPerPage">
                                  <option :value="10">10</option>
                                  <option :value="20">20</option>
                                  <option :value="30">30</option>
                                  <option :value="40">40</option>
                                  <option :value="50">50</option>
                                </select>
                              </li>

                            </ul>
                            <Pagination :total-pages="totalPages" :per-page="recordsPerPage" :current-page="page"
                              @pagechanged="onPageChange" />

                          </div>

                        </td>
                      </tr>
                    </tfoot>
                  </table>
                </div>
              </div>

              <div v-if="loading" class="loading-cover">
                <div class="loader"> </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ReusableDataMixins from '@/mixins/ReusableDataMixins';
import ReusableFunctionMixins from '@/mixins/ReusableFunctionMixins'
import Pagination from '@/pages/pagination/pagination.vue';
import { useToast } from "vue-toastification";
const toast = useToast();
export default {
  components: {
    Pagination,
  },
  name: 'faq-page',
  data() {
    return {
      menuSelected: 0,
      menus: [],
      loading: false,
      catTooltip: 0,
      page: 1,
      totalPages: 10,
      totalRecords: 0,
      recordsPerPage: 10,
      methodSpinner: null,
      spinner: "<i class='fa fa-spinner fa-spin '></i>",
      menuIds: [],
      menuStatus: '',
      debounce: null,
      search: '',
    }
  },
  created: async function () {
    this.getMenus();
  },
  methods: {
    startDrag(event, menu) {     
      event.dataTransfer.dropEffect = 'move'      
      event.dataTransfer.effectAllowed = 'move' 
      event.dataTransfer.setData('itemId',menu.id);
      console.log('dragable item',menu);     
      // evt.dataTransfer.setData('itemID', item.id)    
    },
    async onDrop(event,menu){
      const menuid = event.dataTransfer.getData('itemId')
      try{
        const config = {
            headers: { 'Authorization': `Bearer ${this.token}`, 'Accept': 'application/json' }
        };
        const url = `${this.baseUrl}/menu/position`;
        const payload = {
          'merchant_id': parseInt(this.merchantID),
          'parent_id': '',
          'operation_type': 0,
          'update':{
            'id':menuid,
            'position': menu.position
          }
        }
        const response = await this.axios.patch(url,payload, config);
        if(response.status ==200){
          this.getMenus();
        }

      }catch(error){
        console.log('error')
      }      
    },
    searchMenus() {
      clearTimeout(this.debounce);
      this.debounce = setTimeout(() => {
        this.getMenus();
      }, 600)
    },
    checkItems(type) {
            document.querySelector('#uncheck').indeterminate = true;
            if (type === 'check') {
                if (document.getElementById('check_all').checked) {
                    document.getElementById('filter_bar').style.display = 'block'
                    this.menuSelected = 0;
                    for (const menu of Object.values(this.menus)) {
                        this.menuSelected += 1;
                        document.getElementById('menu_' + menu.id).checked = true
                        if (this.menuIds.includes(menu.id) === false) {
                            this.menuIds.push(menu.id);
                        }
                    }
                } else {
                    document.getElementById('filter_bar').style.display = 'none'
                    for (const menu of Object.values(this.menus)) {
                        document.getElementById('menu_' + menu.id).checked = false
                    }
                    this.menuIds = []
                }

            } else {
                document.getElementById('filter_bar').style.display = 'none'
                document.getElementById('check_all').checked = false
                for (const menu of Object.values(this.menus)) {
                    document.getElementById('menu_' + menu.id).checked = false
                }
                this.menuIds = []
            }

        },
    selectMenus(menuId) {
      if (document.getElementById('menu_' + menuId).checked) {
        if (this.menuIds.includes(menuId) === false) {
          this.menuIds.push(menuId);
        }
        document.getElementById('filter_bar').style.display = 'block'
      } else {
        if (this.menuIds.indexOf(menuId) !== -1) {
          this.menuIds = this.menuIds.filter((x) => x !== menuId);
        }
        for (const menu of Object.values(this.menus)) {
            if (menu.id != menuId && document.getElementById('menu_' + menu.id).checked) {
                document.getElementById('filter_bar').style.display = 'block'
                break;
            } else {
                document.getElementById('filter_bar').style.display = 'none'
            }
        }
      }

      this.menuSelected = 0;
      for (const menu of Object.values(this.menus)) {
          if (document.getElementById('menu_' + menu.id).checked) {
              this.menuSelected += 1;
          }
      }
    },
    bulkStatusUpdate(method, status) {
      this.updateStatus(this.menuIds, method, status);
    },
    updateStatsPerItem(menuId, status) {
      const menuIdsArr = [];
      if (menuIdsArr.includes(menuId) === false) {
        menuIdsArr.push(menuId)
      }
      this.updateStatus(menuIdsArr, '', status)
    },
    updateStatus(menuarr, method, status) {
      if (menuarr.length > 0) {
        this.methodSpinner = method;
        if (method === 'publish' && status === false) {
          this.methodSpinner = 'unpublish'
        }
        const payload = {
          "merchant_id": parseInt(this.merchantID),
          "ids": menuarr,
          "status": status
        };
        const config = {
          headers: { 'Authorization': `Bearer ${this.token}`, 'Accept': 'application/json' }
        };
        const url = this.baseUrl + "/menu/status";
        this.axios.patch(url, payload, config).then((response) => {
          this.menuIds = [];
          if (response.status === 200) {
            toast.success('updated successfully')
            this.methodSpinner = null;
            this.getMenus();
            // 
          }
        }).catch((error) => {
          this.methodSpinner = null;
          if (error.response.status === 401) {
            this.$router.push({
              name: 'login'
            });
          }
        });
      }
    },
    async getMenus() {
      this.loading = true;
      // const url = `${this.baseUrl}//menu?page=${this.page}&size=${this.recordsPerPage}&merchant_id=${this.merchantID}&query=${this.search}`;
      let url = `${this.baseUrl}//menu`;
      url = new URL(url);
      url.searchParams.set('page', this.page);
      url.searchParams.set('size', this.recordsPerPage);
      url.searchParams.set('query', this.search);
      url.searchParams.set('status', this.menuStatus);
      url.searchParams.set('merchant_id', this.merchantID);
      const config = {
        headers: { 'Authorization': `Bearer ${this.token}`, 'Accept': 'application/json' }
      };

      this.axios.get(url, config).then((response) => {
        if (response.status === 200) {
          this.menus = response.data.data;
          this.totalPages = Math.ceil(response.data.total / this.recordsPerPage);
          this.totalRecords = response.data.total_count;
        }
      }).catch((error) => {
        if (error.response.status === 401) {
          this.$router.push({
            name: 'login'
          });
        }
      }).finally(() => this.loading = false);
    },
    onPageChange(page) {
      this.menuIds = []
      document.getElementById('check_all').checked = false;
      document.getElementById('filter_bar').style.display='none';
      this.page = page
      this.getMenus();
    },
    onChangeRecordsPerPage() {
      this.getMenus();
      // ds
    }
  },
  mixins: [ReusableFunctionMixins, ReusableDataMixins]
}
</script>
